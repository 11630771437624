import { render, staticRenderFns } from "./productFavour.vue?vue&type=template&id=36114493&scoped=true&"
import script from "./productFavour.vue?vue&type=script&lang=js&"
export * from "./productFavour.vue?vue&type=script&lang=js&"
import style0 from "./productFavour.vue?vue&type=style&index=0&id=36114493&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36114493",
  null
  
)

export default component.exports