<template>
  <div class="page">
    <div class="title">商品收藏</div>
    <div class="main">
      <div class="box">
        <div class="ops">
          <div class="tosearch">
            <el-input placeholder="请输入检索内容" v-model="searchcontent">
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getdatalist"
              ></el-button>
            </el-input>
          </div>
          <div class="right">
            <el-button @click="exptmygds" :disabled="!isselect">导出数据</el-button>
            <el-button :disabled="!isselect" @click="del(-1)"
              >批量删除</el-button
            >
          </div>
        </div>
        <div class="datainfo">
          <el-table
            @selection-change="handleSelectionChange"
            :data="datalist"
            row-key="id"
            style="width: 100%"
            height="100%"
          >
            <el-table-column
              type="selection"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column prop="img" label="图片" width="90" align="center">
              <template slot-scope="scope">
                <img
                  class="dataimg"
                  :src="scope.row.img"
                  height="40"
                  width="40"
                />
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="商品名称"
              align="center"
              min-width="300"
            ></el-table-column>
            <el-table-column
              prop="provider"
              label="供应商"
              width="170"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="type"
              label="类目"
              width="170"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="time"
              label="时间"
              width="170"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" width="120" align="center">
              <template slot-scope="scope">
                <a class="pcolor del" @click="del(scope.row.id)">删除</a>
              </template>
            </el-table-column>
          </el-table>
          <div class="pageinfo">
            <el-pagination
              :hide-on-single-page="true"
              background
              layout="total, prev, pager, next"
              @current-change="pagechange"
              :current-page="pageinfo.pagenum"
              :page-size="pageinfo.pagesize"
              :total="pageinfo.total"
            ></el-pagination>
                   <el-dialog
      :visible.sync="vipDialogVisible"
      width="200px"
      :append-to-body="true"
      :modal-append-to-body="true"
     
      :modal="true"
    >
      <!--      vip弹框-->
      请联系客服续期vip
    </el-dialog>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";


export default {
  data() {
    return {
     
      vipDialogVisible:false,
      searchcontent: "",
      datalist: [],
      multipleSelection: [],
      pageinfo: {
        pagenum: 1,
        pagesize: 10,
        total: 0,
      },
    };
  },
  computed: {
    isselect() {
      return this.multipleSelection && this.multipleSelection.length > 0;
    },
  },
  mounted() {
    this.getdatalist();
  },
  methods: {
    getdatalist() {
      this.axios
        .get("/collects/gdscltslist", {
          params: {
            pagenum: this.pageinfo.pagenum,
            pagesize: this.pageinfo.pagesize,
            search: this.searchcontent ? this.searchcontent : null,
          },
        })
        .then((res) => {
          if (res.code === this.global.CODE.SUCCESS) {
            this.datalist = res.data.data;
            console.log("sdfsdsdg", this.datalist);
            this.pageinfo.total = res.data.total;
            this.pageinfo.pagenum = res.data.pagenum;
          }
        });
    },
  async getpakcount(key, showLog = true) {
      let { data } = await this.axios.get(`/index/getpakcount?pak=${key}`);
      console.info("getpakcount  === ", key, data);
         return new Promise((resolve, reject) => {
                if (data > 0 || !showLog) {
                    resolve(data)
                } else {
                    this.vipDialogVisible=true
                }
            })
    },
   
    async updateActionTime(acName) {
      await this.axios.post("/index/addsvcrecord", {
        pak: acName,
      });
    },
   async exptmygds() {
       await this.getpakcount("expFavpro");
      let myData = [
        { dataIndex: "gid", title: "商品id" },
        { dataIndex: "gurl", title: "商品链接" },
        { dataIndex: "img", title: "商品图片链接" },
        { dataIndex: "name", title: "商品名称" },
       
      ];
      let header = [];
      myData.forEach((item) => {
        header.push(item.title);
      });

      const columns = myData.map((item) => {
        return {
          header: item.title,
          key: item.dataIndex,
        };
      });
      const dataList = this.multipleSelection.map((item) => {
       
        return item;
      });
      this.setStyle(
        header,
        columns,
        dataList,
        "商品收藏报表" + dayjs().format("YYYY-MM-DD HH:mm:ss")
      );
      this.updateActionTime("expFavpro")
    },
   
    pagechange(v) {
      this.pageinfo.pagenum = v;
      this.getdatalist();
    },
    del(id) {
      if (id !== -1) {
        this.axios
          .post("/collects/delgdscltsbatch", {
            ids: [id],
          })
          .then((res) => {
            if (res.code === this.global.CODE.SUCCESS) {
              this.getdatalist();
            }
          });
      } else if (id === -1 && this.multipleSelection.length > 0) {
        this.axios
          .post("/collects/delgdscltsbatch", {
            ids: this.multipleSelection.map((i) => i.id),
          })
          .then((res) => {
            if (res.code === this.global.CODE.SUCCESS) {
              this.getdatalist();
            }
          });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
  
    
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  color: #313131;
}

.main {
  min-width: 780px;
  background-color: #fff;
  border-radius: 12px;
  margin: 15px 0;
  padding: 28px;
}

.main .box {
  border-radius: 12px;
  box-shadow: 0 2px 5px 0 #ddd;
  padding: 20px;
  color: #666;
}

.box .ops {
  display: flex;
  justify-content: space-between;
}

.box .ops >>> .el-input__inner {
  height: 30px;
  line-height: 30px;
  border: 1px solid #ccc;
}

.box .ops .tosearch {
  width: 230px;
}

.box .ops >>> .el-input__inner:hover {
  border: 1px solid #ff6a00;
}

.box .ops >>> .el-input-group__append:hover {
}

.box .ops .right button {
  margin-left: 10px;
  height: 30px;
  padding: 0 12px;
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #ff6a00;
  color: #fff;
}

button:hover {
  cursor: pointer;
}

.box .ops .right button:disabled {
  background-color: #aaa;
}

.box .datainfo {
  padding: 20px 0;
}

>>> .el-table__body-wrapper {
  height: calc(100% - 60px) !important;
  font-size: 14px;
}

.datainfo .dataimg {
  border: 1px solid #ccc;
}

.datainfo .del:hover {
  cursor: pointer;
}

.pageinfo {
  display: flex;
  justify-content: center;
}
</style>